
	@import "../../variables.less";
	@import "../../resource/common.less";

	@currentWidth: 600px;
	@attachWidth: 150px;
	.btn_right{
        display:flex;
        position:absolute;
        right: 0;
        top: 8px;
    }
    /deep/ .sameBg{
        text-align: center;
        img{
            width: 35px;
            cursor:pointer;
        }
    }
	.top {
		width: 100%;
		height: 36%;
	}
	.bottom {
		border-radius: 4px;
        background:@contentBg;
        box-shadow: 0 0 10px 1px @boxshadow;
        margin: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
	}
	.roomProperty{
		text-align:center;
		color: #000;
		& .m-t-15{
			margin-top: 15px;
			display: flex;
		}
    }
	.qr{
		width:116px;
		height: 116px;
		position:absolute;
		right: 85px;
		top:15px;
		border-radius: 20px;
		padding: 10px;
		div {
			width:116px;
			height: 116px;
		}
    }
	 .roomMsg{
        height:calc(~"100% - 283px");
    }
    .roomMsg1{
        height:95%;
    }

    .roomDetail{
        height:calc(~"100% - 56px");
        position:relative;
        padding-top: 10px;
        box-sizing: border-box;
        & .pageBox{
            padding-bottom: 0!important;
        }
        & .pagebox{
            position: absolute;
            bottom: 15px;
            right:30px;
        }
    }
	.roomMsgTable {
		overflow: auto;
	}
	/deep/ .ivu-table-small td {
		height: 30px;
	}
	/deep/ .ivu-tabs-bar {
		margin-bottom: 0px;
	}
    #QRCode{
        margin: auto;
        width: 165px;
        height: 165px;
    }
    .qr_code_title{
        display:flex;
        justify-content:center;
        align-items: center;
        color:#333;
        font-weight: 600;
        padding: 20px;
        img{
            width: 25px;
        }
    }
    .qr_code_table{
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        width: 85%;border-spacing: 0;
        margin: 0 auto 10px;
        tr{
            td{
                padding: 2px;
                min-width: 100px;
                text-align: center;
                border-left: 2px solid #333;
                border-bottom: 2px solid #333;
            }
        }
    }
