.floor {
  width: 80px;
  cursor: pointer;
  height: 10px;
  color: #FFF;
}
.floor .colorBar {
  background-color: rgba(255, 255, 255, 0.6);
  height: 6px;
}
.floor .info {
  display: none;
  background-image: url("./pin.png");
  padding-left: 18px;
  position: absolute;
  left: 100%;
  width: 80px;
  top: -7px;
}
.floor:hover .info {
  display: block;
  z-index: 999;
}
.floor:hover .colorBar,
.floor:hover ~ .floor:not(:last-child) .colorBar {
  background-color: rgba(30, 144, 255, 0.6);
}
.floor.active .info {
  display: block;
}
.floor.active.active .colorBar,
.floor.active ~ .floor:not(:last-child) .colorBar {
  background-color: rgba(0, 255, 154, 0.6);
}
.allFloor {
  color: white;
  height: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.allFloor.active {
  background-color: rgba(0, 255, 154, 0.6);
}
