
@import "../../variables.less";

.floor {
	width: 80px;
	cursor: pointer;
	height: 6px + @smallSpace;
	color: #FFF;

	.colorBar {
		background-color: rgba(255, 255, 255, 0.6);
		height: 6px;
	}

	.info {
		display: none;
		background-image: url("./pin.png");
		padding-left: 18px;
		position: absolute;
		left: 100%;
		width: 80px;
		top: -7px
	}

	&:hover {
		.info {
			display: block;
			z-index: 999;
		}

		&, & ~ .floor:not(:last-child) {
			.colorBar {
				background-color: rgba(30, 144, 255, 0.6);
			}
		}
	}

	&.active {
		.info {
			display: block;
		}

		&.active, & ~ .floor:not(:last-child) {
			.colorBar {
				background-color: rgba(0, 255, 154, .6);
			}
		}
	}
}

.allFloor {
	color: white;
	height: 20px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.6);
	cursor: pointer;

	&.active {
		background-color: rgba(0, 255, 154, .6);
	}
}
